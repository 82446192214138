var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sun-footer",
    {
      staticClass: "border-t-2 border-gray-300",
      attrs: { color: "white", "text-color": "black", variant: "relative" },
    },
    [
      _c(
        "div",
        { staticClass: "w-full flex flex-wrap justify-between items-center" },
        [
          _c("p", { staticClass: "text-xs" }, [
            _vm._v(
              "©" + _vm._s(_vm.year) + " " + _vm._s(_vm.rightsReservedText)
            ),
          ]),
          _c("p", { staticClass: "text-xs" }, [
            _vm._v("v" + _vm._s(_vm.version)),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }