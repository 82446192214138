var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "sun-button",
        {
          staticClass: "rounded-full px-4",
          on: {
            click: function ($event) {
              _vm.isOpen = !_vm.isOpen
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _c("user-svg", { staticClass: "h-4" }),
              _c("menu-dots-svg", { staticClass: "text-white h-4 ml-1" }),
            ],
            1
          ),
        ]
      ),
      _vm.isOpen
        ? _c("div", {
            staticClass: "fixed inset-0",
            attrs: { tabindex: "-1" },
            on: {
              click: function ($event) {
                _vm.isOpen = false
              },
            },
          })
        : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isOpen
          ? _c(
              "div",
              {
                staticClass:
                  "mt-2 absolute right-0 text-left text-gray-800 mr-auto z-20",
              },
              [
                _c(
                  "div",
                  { staticClass: "w-64 bg-white rounded-lg shadow-lg z-20" },
                  [
                    _c("div", { staticClass: "flex items-center px-6 py-4" }, [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("user-svg", {
                            staticClass: "h-6 w-6 text-gray-600",
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 flex flex-grow flex-col max-w-full truncate",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-semibold text-gray-800 cursor-default leading-none h-4 overflow-hidden truncate whitespace-normal",
                              attrs: {
                                title:
                                  _vm.user && _vm.user
                                    ? _vm.user.name
                                    : _vm.loadingText,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user && _vm.user
                                      ? _vm.user.name
                                      : _vm.loadingText
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.user
                            ? _c(
                                "p",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "text-sm text-gray-600 leading-none hover:underline",
                                      attrs: { to: { name: "profile" } },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.userTexts.profileButtonText
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "border-t-2 border-gray-200 py-1" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "block px-6 py-3 leading-tight hover:bg-gray-200 cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.logOut()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.userTexts.signOutButtonText) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm.isOpen
        ? _c("div", {
            staticClass: "fixed inset-0 z-10",
            attrs: { tabindex: "-1" },
            on: {
              click: function ($event) {
                _vm.isOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }