<template>
  <sun-footer class="border-t-2 border-gray-300" color="white" text-color="black" variant="relative">
    <div class="w-full flex flex-wrap justify-between items-center">
      <p class="text-xs">&copy;{{ year }} {{ rightsReservedText }}</p>
      <p class="text-xs">v{{ version }}</p>
    </div>
  </sun-footer>
</template>

<script>
import { YEAR } from '@/utils/dateTime/year';
import { COMMON_TEXTS } from '@/i18n/commonTexts';

export default {
  name: 'AsterixFooter',
  data: () => ({
    year: YEAR,
    rightsReservedText: COMMON_TEXTS.en.rightsReservedText,
  }),
  computed: {
    version() {
      return (
        process.env.VUE_APP_VERSION +
        (['preproduction', 'staging'].includes(process.env.NODE_ENV) ? `-${process.env.VUE_APP_BUILD_DATE}` : '')
      );
    },
  },
};
</script>
