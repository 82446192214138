<template>
  <section>
    <sun-button class="rounded-full px-4" @click="isOpen = !isOpen">
      <div class="flex items-center">
        <user-svg class="h-4" />
        <menu-dots-svg class="text-white h-4 ml-1" />
      </div>
    </sun-button>
    <div v-if="isOpen" class="fixed inset-0" tabindex="-1" @click="isOpen = false" />
    <transition name="fade">
      <div v-if="isOpen" class="mt-2 absolute right-0 text-left text-gray-800 mr-auto z-20">
        <div class="w-64 bg-white rounded-lg shadow-lg z-20">
          <div class="flex items-center px-6 py-4">
            <div class="flex">
              <user-svg class="h-6 w-6 text-gray-600" />
            </div>
            <div class="ml-4 flex flex-grow flex-col max-w-full truncate">
              <p
                class="font-semibold text-gray-800 cursor-default leading-none h-4 overflow-hidden truncate whitespace-normal"
                :title="user && user ? user.name : loadingText"
              >
                {{ user && user ? user.name : loadingText }}
              </p>
              <p v-if="user">
                <router-link class="text-sm text-gray-600 leading-none hover:underline" :to="{ name: 'profile' }">
                  {{ userTexts.profileButtonText }}
                </router-link>
              </p>
            </div>
          </div>
          <div class="border-t-2 border-gray-200 py-1">
            <span class="block px-6 py-3 leading-tight hover:bg-gray-200 cursor-pointer" @click="logOut()">
              {{ userTexts.signOutButtonText }}
            </span>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="isOpen" class="fixed inset-0 z-10" tabindex="-1" @click="isOpen = false" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT, USER } from '@/store/modules/auth/keys';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';

export default {
  name: 'AsterixUserMenu',
  components: {
    MenuDotsSvg: () => import('@/components/icons/MenuDotsSvg'),
    UserSvg: () => import('@/components/icons/UserSvg'),
  },
  data() {
    return {
      isOpen: false,
      loadingText: 'Loading...',
      userTexts: {
        profileButtonText: 'View Profile',
        signOutButtonText: 'Sign out',
        sections: {
          config: [
            {
              value: 'Settings and Privacy',
              url: '#',
            },
            {
              value: 'Language',
              url: '#',
            },
          ],
          notifications: [
            {
              value: 'Advertise',
              url: '#',
            },
            {
              value: 'Analytics',
              url: '#',
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: [USER],
    }),
    isContextBoard() {
      return this.$route.name.includes(CONTEXT_BOARD_BASE);
    },
  },
  methods: {
    ...mapActions([AUTH_LOGOUT]),
    async logOut() {
      await this[AUTH_LOGOUT]();
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
